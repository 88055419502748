import React, { useEffect, useMemo } from 'react';
import { Link, Redirect, useParams } from 'react-router-dom';
import { getView } from 'shared/foreground/stateGetters';

import fixedEncodeURIComponent from '../utils/fixedEncodeURIComponent';
import updateDocumentTitle from '../utils/updateDocumentTitle';
import NavFolderClosedIcon from './icons/NavFolderClosedIcon';
import styles from './RssFolderPage.module.css';
import StandardPage from './StandardPage';
import standardPageStyles from './StandardPage.module.css';

// Page for a specific folder of feeds. If the folder is empty (empty query) we show
// a message to the user to add feeds to the folder. If not we redirect to the view.
export const RssFolderPage = () => {
  const { rssFolderFilteredViewId } = useParams<{ rssFolderFilteredViewId: string; }>();

  const filteredView = useMemo(() => getView(rssFolderFilteredViewId), [rssFolderFilteredViewId]);
  const folderName = filteredView?.name || '';

  useEffect(() => {
    if (folderName) {
      updateDocumentTitle(folderName);
    }
  }, [folderName]);

  if (!filteredView) {
    return <Redirect exact to="/" />;
  }

  if (filteredView.query) {
    return <Redirect to={`/filter/${fixedEncodeURIComponent(filteredView.query)}`} />;
  }

  return (
    <StandardPage>
      <div className={standardPageStyles.standardPageWrapper}>
        <div className={`${standardPageStyles.header} ${styles.rssFolderHeader}`}>
          <NavFolderClosedIcon />
          <h1 className={standardPageStyles.title}>{folderName}</h1>
        </div>

        <div className={`${standardPageStyles.contentMainWrapper} has-visible-scrollbar`}>
          <div className={`${standardPageStyles.contentWrapper} ${styles.contentMainWrapper}`}>
            <NavFolderClosedIcon />
            <h2>Folder empty</h2>
            <p>
              Drag and drop feeds into the folder in the sidebar <br /> from the{' '}
              <Link to="/feed/sources">Manage feeds</Link> page.
            </p>
          </div>
        </div>
      </div>
    </StandardPage>
  );
};
